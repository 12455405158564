 
<template>
    <div class="wrapper">
    <div class="layouter">
      <div class="search-box">
        <input type="text" name="search" v-model="title" class="search-inpt" placeholder="请输入关键字搜索">
        <img src="@/assets/icon_search.png" @click="serachList" alt="" class="search-icon">
      </div>
    </div>
    <van-skeleton v-if="currentList==-1" title :row="3" />
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="diyCell" v-for="item in list" :key="item.id" :disabled="item.votingStatus == '已结束'" @click="toDetail(item)">
        <div class="top fx_ai">
          <div class="title van-ellipsis fx1">{{ item.name }}</div>
          <van-tag size="mini" :color="item.votingStatus == '已结束'? '#FBE7E6' : '#DAF5DC'" :text-color="item.votingStatus == '已结束'? '#E35D58' : '#0DB918' ">{{ item.votingStatus }}</van-tag>
        </div>
        <div class="time">
          <span>{{ item.createDate }}</span>
        </div>
        <p class="van-multi-ellipsis--l3">{{ item.greeting }}</p>
        <van-divider style="margin: .21rem 0" />
        <div class="fx_ai">
          <div class="fx1 paperCount">{{ item.paperCount }}居民已投</div>
          <van-button style="padding:.32rem" type="info" size="mini" >立即参与</van-button>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script> 
import { getVotingList } from '@/api/votingApi' 

export default {
  name: 'Message',
  components: {
  }, 
  data() {
    return {
        list: [],
        current: 1,
        currentList: -1,
        loading: false,
        finished: false,
        finishedText: '',
        title: '',
    }
  },
  // 计算属性
  computed: {},

  // watch
  watch: {},

  // 创建完成（可以访问当前this实例）
  created() { 
  }, 
  mounted() {},   
  // 方法集合
  methods: {
    toDetail(item) { 
      this.$router.push({path: '/votingDetail', query: {id: item.id}})
    }, 
    serachList(){
      this.current = 1
      this.currentList = -1
      this.list = []
      this.finished = false
      this.onLoad()
    },
    async onLoad() {    
      if (this.currentList !== 0) {  
        const res = await getVotingList({ 
          status: 1, 
          state: 2,
          isWx: 1,
          pageNo: this.current, 
          name: this.title, 
          pageSize: '10', 
          openId: this.$store.getters.openId 
        })   
        this.currentList = res.data ? res.data.length : 0
        this.list = this.list.concat(res.data)
        this.current++ 
        this.loading = false 
      } else {
        this.finished = true; 
        this.finishedText = '没有更多了'
      } 
    },
  }
}
</script>

<style scoped lang='scss'>
@import '@/style/discussList.scss';
</style>
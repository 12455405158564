import axios from '@/utils/axios';
const baseURL = '/api'
// 电子投票

// 列表
export const getVotingList = (params) => {
  return axios({
    baseURL,
    // url: '/convenient/questionnairepaperanswer/unfilledPaperList',
    url: '/convenient/questionnairepaperanswer/list',
    method: 'get',
    params
  });
};
// 详情
export const getVotingDetail = (params) => {
  return axios({
    baseURL,
    url: '/convenient/questionnairepaperanswer/detail',
    method: 'post',
    params,
    data: params
  });
};
// 投票统计详情
export const statistics = (params) => {
  return axios({
    baseURL,
    url: '/convenient/questionnaire/statistics',
    method: 'post',
    params,
    data: params
  });
};
// 提交 
export const submit = (data) => {
  return axios({
    baseURL,
    url: '/convenient/questionnairepaperanswer/q/submit',
    method: 'post',
    data
  });
};